html {
    font-size: 16px;
}

@media (max-width: 1024px) {
    html {
        font-size: 14px;
    }
}

body {
    margin: 0;
    padding: 0;
    font-family: 'Inter', 'Arial', serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-x: hidden;
    font-size: 16px;
}

#root {
    position: relative;
    height: 100vh;
    width: 100vw;
    max-width: 100%;
    overflow: hidden;
    min-height: -webkit-fill-available;
}
