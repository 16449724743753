@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,500;0,600;1,700&display=swap");

/* Import Material Symbols Outlined font */
@import url('https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200');

/* Import Material Symbols Rounded font */
@import url('https://fonts.googleapis.com/css2?family=Material+Symbols+Rounded:opsz,wght,FILL,GRAD@48,400,1,0');

.chatbot * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

@keyframes color {
    0% {
        background-position: 0 50%;
    }

    50% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0 50%;
    }
}

.chatbot-toggler {
    position: fixed;
    bottom: 50%;
    right: 10px;
    outline: none;
    border: none;
    height: 40px;
    width: 40px;
    display: flex;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: #4aa017;
    transition: all 0.2s ease;
    box-shadow: 0 0 128px 0 rgba(0, 0, 0, 0.1), 0 32px 64px -48px rgba(0, 0, 0, 0.5);

    &:hover {
        background: #4aa017;
    }

    svg path {
        fill: #fff;
    }

    @media (max-width: 991px) {
        bottom: 35px;
        right: 5px;
    }

    @media (max-width: 767px) {
        bottom: 35px;
        right: 5px;
    }
}
.chatbot-toggler.opened{
    @media (max-width: 767px) {
        right: 5px;
        top: 10px;
        z-index: 99999;
    }
}

body.show-chatbot .chatbot-toggler {
    transform: rotate(90deg);
    background: #4aa017;
}

.chatbot-toggler span {
    color: #fff;
    position: absolute;
    font-size: 1.2rem;
    font-weight: 600;
    transition: all 0.2s ease;
}

.chatbot-toggler span:last-child,
body.show-chatbot .chatbot-toggler span:first-child {
    opacity: 0;
}

body.show-chatbot .chatbot-toggler span:last-child {
    opacity: 1;
}

.chatbot {
    position: fixed;
    right: 50px;
    bottom: 25px;
    overflow: hidden;
    width: 440px;
    height: 50%;
    transform: scale(0.5);
    opacity: 0;
    pointer-events: none;
    background: #fff;
    transform-origin: bottom right;
    border-radius: 15px;
    box-shadow: -4px 1px 8px 2px rgba(58, 58, 68, 0.16);
    transition: all 0.1s ease;
    z-index: 9999;
}

body.show-chatbot .chatbot {
    opacity: 1;
    pointer-events: auto;
    transform: scale(1);
}

/* ChatBot */
.chatbot header {
    background: #4aa017;
    position: relative;
    color: #fff;
    padding: 15px 0;
    text-align: center;
    border-radius: 15px 15px 0 0;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.chatbot header span {
    position: absolute;
    right: 15px;
    top: 50%;
    display: none;
    cursor: pointer;
    transform: translateY(-50%);
}

@media screen and (max-width: 992px) {
    .chatbot header span {
        display: block;
    }
}

.chatbot header span svg {
    height: 20px;
    width: 20px;
    fill: #fff;
}

.chatbot header span:hover {
    opacity: 0.8;
}

.chatbot header span:first-child {
    right: 55px;
}

.chatbot header span:last-child {
    right: 15px;
}

.chatbot header h2 {
    color: #fff;
    font-size: 1.4rem;
    font-weight: 600;
    letter-spacing: 0.5px;
}

.chatbot .chatbox {
    overflow-y: auto;
    padding: 15px 10px 8px;
    height: 300px;
    background: #fff;
    border-radius: 0 0 15px 15px;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.1);
}

.chatbox .chat {
    display: flex;
    list-style: none;
    margin: -1px 0 0;
}

.chatbot :where(.chatbox, textarea)::-webkit-scrollbar {
    width: 6px;
}

.chatbot :where(.chatbox, textarea)::-webkit-scrollbar-track {
    background: #fff;
    border-radius: 25px;
}

.chatbot :where(.chatbox, textarea)::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 25px;
}

.chatbot :where(.chatbox, textarea)::-webkit-scrollbar-thumb:hover {
    background: #b3b3b3;
}

.chatbox .incoming span {
    height: 30px;
    width: 30px;
    color: #000;
    align-self: flex-start;
    background: transparent;
    text-align: center;
    line-height: 32px;
    border-radius: 5px;
    margin: 0 8px 2px 0;
}

.chatbox .outgoing {
    margin: 20px 0;
    justify-content: flex-end;
    font-size: 0.8rem;
}

.chatbox .chat p {
    color: #fff;
    font-size: 0.9rem;
    max-width: 75%;
    padding: 5px 10px;
    border-radius: 10px 10px 0 10px;
    background: #4aa017;
    line-height: 1.3;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.1);
}

.chatbox .incoming p {
    color: black;
    font-size: 0.9rem;
    background: #f2f2f2;
    border-radius: 10px 10px 10px 0;
    text-align: left;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.1);
}

.chatbox .chat p.error {
    color: #721c24;
    background: #f8d7da;
}

.chatbox .chat p.error::before {
    content: "!";
    color: #721c24;
    font-weight: 600;
    margin-right: 5px;
}

.chatbox .chat p.error::after {
    content: "!";
    color: #721c24;
    font-weight: 600;
    margin-left: 5px;
}

.chatbot .chat-input {
    position: absolute;
    bottom: 0;
    width: 100%;
    display: flex;
    gap: 5px;
    background: #fff;
    padding: 5px 20px;
    border-top: 1px solid #ccc;
}

.chat-input textarea {
    height: 55px;
    width: 100%;
    border: none;
    outline: none;
    font-size: 0.95rem;
    resize: none;
    padding: 16px 15px 16px 0;
    border-radius: 5px;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.1);
    font-family: 'Inter', 'Arial', serif;
    max-height: 55px;
}

.chat-input span {
    align-self: flex-end;
    height: 40px;
    line-height: 55px;
    color: #4aa017;
    font-size: 1.35rem;
    cursor: pointer;
    visibility: hidden;
    transition: 0.3s ease;
    justify-content: flex-end;
}

.chat-input textarea:valid~span {
    visibility: visible;
}

@media (max-width: 767px) {
    .chatbot {
        width: 100%;
        height: 100%;
        border-radius: 0;
        bottom: 0;
        right: 0;
    }
    .chatbot .chatbox {
        overflow-y: auto;
        padding: 15px 10px 8px;
        height: 80vh;
    }
    .chatbot header {
        border-radius: 0;
    }

    .chatbot .chat-input {
        position: relative;
    }

    .chatbot .chat-input textarea {
        padding: 16px 15px 16px 0;
        max-height: 80px;
    }

    .chatbot .chat-input span {
        height: 55px;
        line-height: 55px;
        font-size: 1.35rem;
    }
}

@media (max-width: 575px) {
    .chatbot {
        width: 100%;
        height: 100%;
        border-radius: 0;
        bottom: 0;
        right: 0;
    }

    .chatbot header {
        border-radius: 0;
    }

    .chatbot .chat-input {
        position: relative;
    }

    .chatbot .chat-input textarea {
        padding: 16px 15px 16px 0;
    }

    .chatbot .chat-input span {
        height: 55px;
        line-height: 55px;
        font-size: 1.35rem;
    }
}