@import 'react-big-calendar/lib/sass/styles';

.rbc-calendar{
  padding: 20px;
}
.rbc-time-content {
  display: none;
}
.rbc-day-bg + .rbc-day-bg, .rbc-month-view {
  border-color: #e9e9e9;
}
.rbc-toolbar button:active, .rbc-toolbar button.rbc-active, .rbc-toolbar button:hover, .rbc-toolbar button.rbc-active:focus{
  border-color: #1890ff;
  color: #1890ff;
  background-color: transparent;
  box-shadow: none;
}
.rbc-time-view{
  z-index: 0;
}
.rbc-label{
  display: none;
}
.rbc-toolbar-label {
  font-weight: bold;
}
@media (max-width: 767px) {
  .rbc-toolbar{
    .rbc-btn-group {
      order: 2;
      margin-top: 4px;
      margin-bottom: 4px;
    }
    .rbc-toolbar-label {
      order: 1;
    }
  }
  .rbc-show-more {
    font-size: 12px;
  }
  .rbc-month-row {
    flex-basis: auto !important;
    .rbc-row-content-scrollable {
      .rbc-row-content-scroll-container {
        min-height: 150px !important;
      }
    }
  }
}
.home-page-calendar {
  .rbc-calendar{
    padding: 0;
    @media (min-width: 1024px) {
      padding-left: 10px;
      padding-right: 10px;
    }
  }
  .home--custom-toolbar{
    flex-direction: column;
    .rbc-btn-group {
      order: 2;
      margin-top: 4px;
      margin-bottom: 4px;
    }
    .rbc-toolbar-label {
      order: 1;
    }
  }
}