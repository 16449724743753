.message-input {
    width: 100%;
  }
  
  .message-input__composer {
    display: flex;
    align-items: center;
    border: 2px solid #00000029;
    border-radius: 8px;
  }
  
  .message-input__composer:has(.message-input__input:focus) {
    border-color: #005fff;
  }
  
  .message-input__input {
    flex-grow: 1;
    border: 0;
    outline: 0;
    background: none;
    font: inherit;
    padding: 8px;
    resize: none;
  }
  
  .message-input__button {
    border: 1px solid transparent;
    outline: 0;
    background: none;
    font: inherit;
    border-radius: 4px;
    margin: 8px;
    padding: 8px;
    cursor: pointer;
  }
  
  .message-input__button:hover {
    background: #fafafa;
    border-color: #00000014;
  }
  
  .message-input__button:focus,
  .message-input__button:focus-within {
    border-color: #005fff;
  }
  
  .suggestions {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    margin-bottom: 10px;
    font-size: 0.9em;
  }
  
  .suggestions__item {
    font: inherit;
    display: flex;
    gap: 5px;
    padding: 5px 10px;
    border-radius: 20px;
    border: 0;
    background: #e9eaed;
    cursor: pointer;
  }
  